import { styled } from '@mui/material/styles';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { MuiBox } from '../../../../../modules/material';

const styledPriceInfoWrapperOptions = {
  shouldForwardProp: (prop: string) =>
    !['isReverseOrderEnabled'].includes(prop),
};

export const StyledPriceInfoWrapper = styled(
  MuiBox,
  styledPriceInfoWrapperOptions,
)<{ isReverseOrderEnabled?: boolean }>(({ theme, isReverseOrderEnabled }) => ({
  display: 'flex',
  flexDirection: isReverseOrderEnabled ? 'column-reverse' : 'column',
  alignItems: 'end',
}));

export const StyledEndPriceWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledVatWrapper = styled('div')({
  whiteSpace: 'nowrap',
});

export const StyledInfoIcon = styled(InfoOutlined)(({ theme }) => {
  return {
    display: 'flex',
    color: theme.palette.neutral[600],
    marginLeft: '4px',
  };
});
