import { ReactNode } from 'react';
import cn from 'classnames';
import { PricingProduct } from '../../../store/features/products/types';
import { isPriceDiscount } from '../../../helpers/shared/isPriceDiscount';
import Price, { PriceProps } from '../Price';
import Tooltip from '../../library/Tooltip';
import { MuiIconButton } from '../../../../../modules/material';
import { BoxProps } from '@mui/material';

import {
  StyledPriceInfoWrapper,
  StyledInfoIcon,
  StyledEndPriceWrapper,
  StyledVatWrapper,
} from './styles';
import { TypographyProps } from '../../library/Typography';

export interface PriceInfoProps extends PriceProps {
  price: PricingProduct['price'];
  startPrice?: PricingProduct['startPrice'];
  discountDetails?: ReactNode;
  vat?: ReactNode;
  isReverseOrderEnabled?: boolean;
  startPriceProps?: TypographyProps;
  boxWrapperProps?: BoxProps;
}

export default function PriceInfo(props: PriceInfoProps) {
  const {
    price,
    startPrice,
    vat,
    discountDetails,
    isReverseOrderEnabled,
    startPriceProps,
    boxWrapperProps,
    ...priceProps
  } = props;
  const showDiscountDetails = isPriceDiscount(price, startPrice);

  return (
    <StyledPriceInfoWrapper
      {...boxWrapperProps}
      isReverseOrderEnabled={isReverseOrderEnabled}
      className={cn(boxWrapperProps?.className, 'informa-payment-price-info')}
    >
      <StyledEndPriceWrapper>
        <Price
          variant="h3"
          {...priceProps}
          className={cn(
            priceProps.className,
            'informa-payment-price-info__end-price',
          )}
        >
          {price}
        </Price>

        {discountDetails && showDiscountDetails && (
          <Tooltip
            title={discountDetails}
            visualStyle="light"
            placement="bottom"
            data-testid="pricing-product-price-tooltip"
          >
            <MuiIconButton sx={{ p: 0 }}>
              <StyledInfoIcon fontSize="small" />
            </MuiIconButton>
          </Tooltip>
        )}
      </StyledEndPriceWrapper>

      {vat && <StyledVatWrapper>{vat}</StyledVatWrapper>}

      {showDiscountDetails && (
        <Price
          variant="smallText"
          neutralColorIndex={700}
          opacity={0.6}
          lineThrough
          {...startPriceProps}
          className={cn(
            startPriceProps?.className,
            'informa-payment-price-info__start-price',
          )}
        >
          {startPrice}
        </Price>
      )}
    </StyledPriceInfoWrapper>
  );
}
